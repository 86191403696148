// Global sEARCH BOX
a.open-search {
  position: absolute;
  top: 20px;
  right: 52px;
  height: 48px;
  width: 48px;
  display: block;
  z-index: 22;
  background: url("/sites/all/themes/custom/nexe_mygs/assets/big_search.png") 0 0 no-repeat;
  background-size: 66%;
  pointer-events: all;


  @include respond-to(xxl) {
    top: 68px;
    right: -4px;
  }

  @include respond-to(xxxl) {
    top: 94px;
    right: 10px;
    background-size: 98%;
  }
}

.field-name-field-autoria-imatge {
  font-size: 12px;

  .views-row & {
    margin-top: -14px;
    display: inline;
    float: left;
  }

  .field-label {
    font-weight: normal;
    margin-right: 4px;
  }
}



#search-box {
  position: fixed;
  min-height: 100vh;
  background-color: color(orange);
  display: none; // Change to NONE for production.
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;

  .custom-search-position {
    background: url(/sites/all/themes/custom/nexe_mygs/assets/nexe_white.png) 25px 8px no-repeat transparent;
    background-size: 54%;
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: 100vh;

    @include respond-to(xxl) {
      max-width: 1016px;
      background-size: 198px;
    }

    @include respond-to(xxxl) {
      max-width: 1380px;
      background-size: 300px;

      a.search-close {
        right: 24px;
      }
    }
  }


  a.search-close {
    position: absolute;
    top: 8px;
    right: 0;
    width: 40px;
    height: 40px;
    background: url("/sites/all/themes/custom/nexe_mygs/assets/search-close.png") 0 0 no-repeat;
    background-size: 70%;

    @include respond-to(xxxl) {
      background-size: 98%;
    }

    .logged-in & {
      top: 84px;
    }
  }


  // Region AND Real Search-FORM
  // SERCH BLOC on NAV TODO :: Change REGION
  .region-search-box {
    position: relative;
    padding: 0 20px;

    form {
      position: relative;
      top: 44vh;
      width: 100%;
      max-width: 700px;
      margin: 0 auto;

      .form-type-textfield {
        label {
          display: none;
        }

        input {
          position: absolute;
          width: 100%;
          padding: 8px;
          border-radius: 0;
          border: medium none;
          font-size: 20px;
          line-height: 30px;

          @include respond-to(xl) {
            font-size: 24px;
            line-height: 36px;
          }
        }
      }

      .form-item-custom-search-types {
        display: none;
      }

      .form-actions.form-wrapper input[type="submit"] {
        color: transparent;
        text-indent: 9999px;
        position: absolute;
        width: 40px;
        height: 40px;
        top: 10px;
        right: -4px;
        background: url("/sites/all/themes/custom/nexe_mygs/assets/search_black.png") no-repeat 0 0 transparent;
        background-size: 70%;
        outline: none;

        @include respond-to(xl) {
          background-size: 90%;
          right: 2px;
        }
      }
    }
  }

  // Si estamos logueados, bajamos para que pueda ser usable... (barra DRUPAL TOP tapa...)
  .logged-in & {
    background-position: 30px 88px;
  }
}

// Global for all pages
.share-buttons {
  display: none;
  position: absolute;
  width: auto;
  height: 43px;
  top: -6px;
  right: 84px;
  padding: 4px 8px 4px 12px;
  background-color: color(orange);

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    float: right;
  }

  li {
    list-style: none;
    width: 33px;
    height: 33px;
    float: left;
    overflow: hidden;
    margin-right: 4px;

    &.ico-facebook {
      background: url(/sites/all/themes/custom/nexe_mygs/assets/icon_fb_white.png) no-repeat 0 0 transparent;

      &:hover {
        background: url(/sites/all/themes/custom/nexe_mygs/assets/icon_fb_black.png) no-repeat 0 0 transparent;
      }
    }

    &.ico-twitter {
      background: url(/sites/all/themes/custom/nexe_mygs/assets/icon_tw_white.png) no-repeat 0 0 transparent;

      &:hover {
        background: url(/sites/all/themes/custom/nexe_mygs/assets/icon_tw_black.png) no-repeat 0 0 transparent;
      }
    }

    &.ico-linked {
      background: url(/sites/all/themes/custom/nexe_mygs/assets/icon_ln_white.png) no-repeat 0 0 transparent;

      &:hover {
        background: url(/sites/all/themes/custom/nexe_mygs/assets/icon_ln_black.png) no-repeat 0 0 transparent;
      }
    }

    a {
      padding: 14px;
      color: transparent !important;
      text-indent: 9999px;
    }
  }
}

// We'll extende to ALL but basic pages.


#title-wrapper {
  margin-top: 0;
  width: 100%;

  .type-label {
    float: left;
    margin-top: 12px;
    width: 50%;

    span {
      color: white;
      background-color: black;
      padding: 6px 8px;
      font-size: 16px;
      line-height: 19px;

      a {
        font-weight: 300;
        color: white;
        text-decoration: none;
      }

      &:hover {
        background-color: color(orange);
      }
    }
  }

  .share-content {
    float: right;
    height: 40px;
    width: 50%;
    text-align: right;
    position: relative;

    .share_open_top {
      float: right;
      height: 30px;
      width: 30px;
      cursor: pointer;
      background: url("/sites/all/themes/custom/nexe_mygs/assets/share_black.png") no-repeat 0 0 transparent;

      &:hover {
        background: url("/sites/all/themes/custom/nexe_mygs/assets/share_orange.png") no-repeat 0 0 transparent;
      }
    }

    .download-top {
      float: right;
      background: url("/sites/all/themes/custom/nexe_mygs/assets/download_file.png") no-repeat 0 0 transparent;
      color: transparent;
      width: 20px;
      height: 40px;
      overflow: hidden;
      margin-right: 12px;
      cursor: pointer;

      &:hover {
        background: url("/sites/all/themes/custom/nexe_mygs/assets/download_file_hover.png") no-repeat 0 0 transparent;
      }

      a {
        color: transparent;
        text-indent: -9999px;
      }
    }
  }

  h1 {
    float: left;
    width: 100%;
    margin: 0 0 12px 0;
    font-size: 30px;
    font-weight: 300;
    text-decoration: none;

    span {
      border-bottom: 1px solid;

      .page-views & {
        border-bottom: medium none;
      }
    }
  }


  &.sticky {

    @include respond-to(xxl) {
      position: fixed;
      left: 0;
      top: 0;
      background-color: transparent;
      z-index: 5;
      width: 100%;

      .title-position {
        margin: 0 auto;
        max-width: 903px;
        padding: 0;

        .title-width {
          float: left;
          background-color: white;
          width: 100%;
          padding: 10px 20px;
          height: auto;

          // Sombra debajo
          -webkit-box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.6);
          -moz-box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.6);
          box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.6);

          .front & {
            display: none;
            visibility: hidden;
            overflow: hidden;
            height: 0;
          }
        }
      }
    }

    @include respond-to(xxxl) {

      .title-position {
        max-width: 1193px;

        .title-width {
          width: 894px;
          padding: 10px 20px;
          height: auto;
        }
      }
    }
  }
}

// HEADER Especific for PAGE
.node-type-page,
.page-search {
  .custom-date {
    display: none;
  }

  #title-wrapper.sticky .title-position {

    @include respond-to(xxl) {
      max-width: 900px;
      //height: 200px;
      //background: red;

      .title-width {
        width: 900px;
        height: 80px;
      }
    }

    @include respond-to(xxxl) {
      max-width: 1192px;
      .title-width {
        width: 1192px;
      }
    }

  }

  &.title-label {
    #title-wrapper h1#main-title {
      position: relative;
      font-size: 16px;
      line-height: 19px;
      color: white;
      background-color: #fb4200;
      text-decoration: none;
      padding: 6px 8px;
      width: auto;
      float: left;
      margin-top: 12px;
      margin-bottom: 30px;

      &:after {
        position: absolute;
        content: ' ';
        background-color: white;
        height: 41px;
        width: 16px;
        left: -20px;
        top: 50px;
      }

      span {
        border-bottom: medium none;
      }
    }
  }
}


// BOTTOM BLOCKS
// Share block
#block-mygs-nexe-share-links {
  position: relative;
  float: left;
  width: 100%;

  @include respond-to(xl) {
    width: 33%;
  }

  &:before {
    position: absolute;
    top: -40px;
    left: -24px;
    content: '';
    width: 24px;
    height: 40px;
    background-color: white;

  }

  h2.block__title {
    margin: 0;
    font-size: 18px;
    font-weight: 300;
    text-decoration: none;
  }

  .share_bottom_open {
    cursor: pointer;
    width: 30px;
    height: 30px;
    background: url("/sites/all/themes/custom/nexe_mygs/assets/share.png") no-repeat 0 0 transparent;

    &:hover {
      background-position: -30px 0;
    }
  }

  .share-buttons {
    right: 68px;
    top: 30px;
  }

}

#block-views-custom-blocs-downloads {
  float: left;
  width: 33%;
  padding-left: 20px;
  border-left: 1px solid;

  h2.block__title {
    margin: 0;
    font-size: 18px;
    font-weight: 300;
    text-decoration: none;
  }

  .views-field-field-descarrega {
    float: left;
    background: url("/sites/all/themes/custom/nexe_mygs/assets/download_file.png") no-repeat 0 0 transparent;
    min-height: 80px;
    width: 40px;
    overflow: hidden;

    &:hover {
      background: url("/sites/all/themes/custom/nexe_mygs/assets/download_file_hover.png") no-repeat 0 0 transparent;
    }

    .file-icon {
      display: none;
    }

    a {
      color: transparent;
      text-indent: -9999px;
      //padding-bottom: 36px;

      &:visited,
      &:focus,
      &:active {
        outline: none;
        color: transparent;
        text-indent: -9999px;
      }
    }
  }
}
