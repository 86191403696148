.layout-3col__right-sidebar {

  .views-field-type {

    .field-content {
      text-transform: capitalize;
      color: white;
      background-color: black;
      padding: 2px 8px;
    }
  }

}
