// Output a number in the $base-unit.
//
// @param number $number
//   The number (without unit) to output.
//
// @return number
//   The number with the base unit
@function typey-output-in-base-unit($number) {
  @if $number == 0 {
    @return 0;
  }
  @if $base-unit == rem {
    @return $number * 1rem;
  }
  @if $base-unit == px {
    @return $number * 1px;
  }
  @if $base-unit == em {
    @return $number * 1em;
  }
}

// Output a number in a chosen unit.
//
// @param number $number
//   The number (without unit) to output.
// @param string $unit
//   (optional) The unit to output, either em, px or rem.
//
// @return number
//   The number with the base unit
@function typey-output-in-unit($number, $unit: $base-unit) {
  @if $number == 0 {
    @return 0;
  }
  @if $unit == rem {
    @return $number * 1rem;
  }
  @if $unit == px {
    @return $number * 1px;
  }
  @if $unit == em {
    @return $number * 1em;
  }
}

// Remove the unit from a number.
//
// @param number $number
//   The number (with unit) to convert. Allowed units: any
//
// @return number
//   The number without the unit.
@function typey-strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}
