// For ALL pages.
p, pre {
  margin: 30px 0;
}

hr {
  display: none;
}

.field-collection-view {
  border: medium none;
}

a {
  font-weight: 500;
  text-decoration: none;

  &:hover {
    color: color(orange);
    text-decoration: none;
  }
}

// We HIDE bradcrumbs for now...
nav.breadcrumb {
  display: none;
  overflow: hidden;
  visibility: hidden;
  height: 0;
}


// Correct GRID here.
.layout-center {
  width: 100%;
  padding-left: 0;
  padding-right: 0;

  @include respond-to(xl) {
    max-width: 834px;
  }

  @include respond-to(xxl) {
    max-width: 904px;
  }

  @include respond-to(xxxl) {
    max-width: 1196px;
  }

  .layout-3col {
    margin: 0;
  }
}

.layout-3col__left-content {
  @include respond-to(xxl) {
    width: 75%;
    border-left: 1px solid black;
    border-right: 1px solid black;
  }
}


// Aside (Second sidebar)
.layout-3col__right-sidebar {

  @include respond-to(xxl) {
    width: 25%;
    border-right: 1px solid;
    //min-height: 1214px;

    .altura-barra-maxima & {
      min-height: 1214px; // Allow smooth SCROLL
    }

    .block-block {
      margin-bottom: 60px;
    }
  }
}

.layout-3col__full {
  @include respond-to(xxl) {
    padding: 0 16px;
    border-left: 1px solid;
    border-right: 1px solid;
  }
}


.layout-3col__right-sidebar {
  display: none;

  .page-node-44 &,
  .page-node-45 & {
    display: block;
  }


  @include respond-to(xl) {
    display: block;
    padding: 0;
  }
}

.front {
  .layout-3col__full {
    padding: 0;
    border-left: medium none;
    border-right: medium none;
  }
}


.scroll_follow {
  position: relative;
  box-sizing: border-box;

  @include respond-to(xxl) {
    position: absolute;

    > div {
      float: left;
      width: 100%;
    }
  }
}

.hide-desktop {
  display: none;
  visibility: hidden;
}

.messages, .messages--status {
  float: left;
  width: 100%;
  position: relative;
  z-index: 900;
}

.front h1#main-title {
  display: none;
  visibility: hidden;
  height: 0;
  overflow: hidden;
}

figure {
  float: left;
  width: 100%;
  margin: 0;
}

figcaption {
  float: left;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 6px;
}

// REGION inside FIXED bloc
.region-fixed-header {
  float: left;
  width: 100%;
  z-index: 15;
  position: relative;
}

// NEW Content Bottom REGION
.region-bottom-content {
  position: relative;
  float: left;
  width: 100%;
  margin: 18px 0;

  @include respond-to(xxl) {
    margin-top: 60px;
    margin-bottom: 0;
  }
}

.field-type-image {
  img {
    width: 100%;
  }
}

// Manual MENU => Qui som / Contacte
#block-block-5 {
  padding-left: 20px;

  .menu-contacte {
    list-style: none;
    padding: 0;
    margin: 10px 0 8px 0;

    li {
      list-style: none;

      &:hover {
        background: none;
      }

      a {
        font-size: 18px;
        line-height: 24px;

        &:hover {
          color: black;
          background: none;
          font-weight: 300;
        }
      }
    }
  }

  .social-icons-wrapper {
    margin-top: 0;

    ul.social-icons-items {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        float: left;
        overflow: hidden;
        margin-right: 6px;
        width: 34px;
        height: 34px;

        &.ico-facebook {
          background: url("/sites/all/themes/custom/nexe_mygs/assets/icon_fb_black.png") no-repeat 0 0 transparent;

          &:hover {
            background: url("/sites/all/themes/custom/nexe_mygs/assets/icon_fb_white.png") no-repeat 0 0 transparent;
          }
        }

        &.ico-twitter {
          background: url("/sites/all/themes/custom/nexe_mygs/assets/icon_tw_black.png") no-repeat 0 0 transparent;

          &:hover {
            background: url("/sites/all/themes/custom/nexe_mygs/assets/icon_tw_white.png") no-repeat 0 0 transparent;
          }
        }

        &.ico-linkedin {
          background: url("/sites/all/themes/custom/nexe_mygs/assets/icon_ln_black.png") no-repeat 0 0 transparent;

          &:hover {
            background: url("/sites/all/themes/custom/nexe_mygs/assets/icon_ln_white.png") no-repeat 0 0 transparent;
          }
        }

        &.ico-instagram {
          background: url("/sites/all/themes/custom/nexe_mygs/assets/icon_insta_black.png") no-repeat 0 0 transparent;

          &:hover {
            background: url("/sites/all/themes/custom/nexe_mygs/assets/icon_insta_white.png") no-repeat 0 0 transparent;
          }
        }

        // LINK inside this list elements...
        a {
          color: transparent;
          text-indent: 9999px;
          padding: 2px 28px 10px 0;

          &:hover {
            background: none;
          }
        }
      }
    }
  }

  @include respond-to(m) {

    .menu-contacte {
      margin: 1.2rem 0;
    }

    .social-icons-wrapper {
      margin-top: 16px;

      ul.social-icons-items {
        margin: 6px 0;
      }
    }
  }

  @include respond-to(xl) {
    padding-left: 40px;

    .menu-contacte {
      margin-bottom: 6px;

      li a {
        font-size: 24px;
        line-height: 32px;
      }
    }

  }
}

// Base TITLE for Blocks
.block__title {
  font-family: $ibmplex;
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  //text-decoration: underline;
  margin-top: 20px;
  //padding-left: 12px;
}

.block-block {

  a {
    font-size: 16px;
    line-height: 22px;
  }

  img {
    width: 100%;
  }
}

// Some Form elements
.form-actions .form-submit {
  border: medium none;
  width: 100%;
  background-color: black;
  color: white;
  font-size: 20px;
  padding: 8px;

  &:hover {
    background-color: color(orange);
  }
}

.form-item {
  margin: 12px 0;

  .input[type=text] {
    width: 100%;
    padding: 2%;

    @include respond-to(xxl) {
      padding: 8px;
    }

  }
}

.field-name-field-enllac {
  float: left;

  .field-label {
    float: left;
    width: auto;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
  }
}

// Etiquetes GLOBAL
.field-name-field-tags,
.field-name-field-text-lliure-autor,
.field-name-field-enllac {
  float: left;
  width: 100%;
  margin: 0;

  .field-label {
    float: left;
    width: auto;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
  }

  .field-item,
  .field-authors-link {
    float: left;
    width: auto;
    padding-right: 10px;
    margin-right: 4px;
    position: relative;

    a {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }

    &:after {
      content: '/';
      position: absolute;
      right: 0;
    }

    &:last-child:after {
      content: '';
    }
  }
}


.field-name-field-enllac,
.field-name-field-text-lliure-autor {
  width: initial;

  .field-authors-link {
    padding-right: 0;

    a {
      font-weight: 500;
    }
  }
}

#sliding-popup {

  .popup-content #popup-text {
    margin: 14px 0 0;
  }

  .decline-button.eu-cookie-compliance-default-button {
    background: none black;
    color: white;
    text-shadow: none;
    box-shadow: none;
    border: medium none;
    border-radius: 0;
  }
}

blockquote {
  text-align: right;
  padding-right: 20px;
  font-style: italic;
  font-size: 22px;
  line-height: 30px;
}

// Share buttons for ALL blocks (remember to configure .node => RELATIVE !!!
.views-row {

  .node .share-wrapper {
    //display: none; // Change to NONE on finish !!!
    position: absolute;
    top: 10px;
    right: 0;
    width: 30px;
    height: 30px;

    .block-share-opener {
      display: block;
      position: absolute;
      top: 5px;
      right: 0;
      height: 30px;
      width: 30px;
      cursor: pointer;
      background: url('/sites/all/themes/custom/nexe_mygs/assets/share_black.png') no-repeat 0 0 transparent;
    }

    .share-buttons {
      //display: block;
      width: 136px;
      right: 32px;
      top: -2px
    }
  }

  &:hover {
    .node .share-wrapper {
      display: block;

      .block-share-opener {
        display: block;
        background: url('/sites/all/themes/custom/nexe_mygs/assets/share_white.png') no-repeat 0 0 transparent;

        &:hover {
          background: url('/sites/all/themes/custom/nexe_mygs/assets/share_black.png') no-repeat 0 0 transparent;
        }
      }
    }
  }
}

// Colors for AGENDA (GLOBAL)
.event-date-container {
  float: left;
  width: 100%;
  height: auto;
  color: white;

  // Day and MONTH
  .event-day {
    float: left;
    width: 40%;
    margin: 20px 0;
    text-align: right;
    font-size: 60px;
    line-height: 72px;
    font-weight: 100;
  }

  .event-month {
    text-transform: uppercase;
    float: left;
    width: 56%;
    margin: 20px 0 20px 4%;
    text-align: left;
    font-size: 60px;
    line-height: 72px;
    font-weight: 100;
  }


  @include respond-to(xl) {
    height: 400px;

    .event-day {
      margin-top: 110px;
      margin-bottom: 0;
      width: 100%;
      text-align: center;
      font-size: 86px;
      line-height: 90px;
    }
    .event-month {
      width: 100%;
      margin: 0;
      text-align: center;
      font-size: 86px;
      line-height: 90px;
    }
  }

  @include respond-to(xxl) {
    height: 188px;

    .event-day {
      margin-top: 30px;
      font-size: 60px;
      line-height: 72px;
    }

    .event-month {
      font-size: 60px;
      line-height: 72px;
    }
  }

  @include respond-to(xxxl) {
    height: 262px;

    .event-day {
      margin-top: 50px;
      font-size: 86px;
      line-height: 90px;
    }

    .event-month {
      font-size: 86px;
      line-height: 90px;
    }
  }

  a {
    color: white;
    font-weight: 100;
    text-decoration: none;
  }

  // Colors for EVENTS
  &.month-gen {
    background-color: #FBEA00;
  }

  &.month-feb {
    background-color: #FFB400;
  }

  &.month-mar {
    background-color: #FF7800;
  }

  &.month-abr {
    background-color: #FB4300;
  }

  &.month-mai {
    background-color: #FF00FE;
  }

  &.month-jun {
    background-color: #520052;
  }

  &.month-jul {
    background-color: #530085;
  }

  &.month-ago {
    background-color: #5201FF;
  }

  &.month-set {
    background-color: #0085FF;
  }

  &.month-oct {
    background-color: #FBEA00;
  }

  &.month-nov {
    background-color: #FBEA00;
  }

  &.month-des {
    background-color: #FBEA00;
  }
}

// VIDEO FIELD
.field-name-field-video {
  margin: 20px 0;
}

// FC Autoria
.field-collection-container {
  border-bottom: medium none;
  float: left;
  width: 100%;
}

.field-name-field-autoria {
  float: left;
  width: 100%;

  .field-label {
    float: left;
    width: auto;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
  }

  > .field-items {
    float: left;
    width: auto;

    > .field-item {
      float: left;
      width: auto;
      //display: inline-block;
      position: relative;
      margin-right: 12px;
      padding-right: 6px;


      &:before {
        content: ',';
        position: absolute;
        right: 0;
      }

      &:last-child {
        padding: 0;
        margin: 0;

        &:before {
          content: '';
        }
      }

      .field-collection-view,
      .content {
        padding: 0;
        margin: 0;

        .field-name-field-autor,
        .field-name-field-text-lliure-autor,
        .field-name-field-enllac {
          float: left;
          width: auto;
        }

        .field-name-field-text-lliure-autor {
          .views-row & {
            display: none;
          }
        }

        .field-name-field-enllac {
          margin-left: 4px;
          padding-left: 8px;
          position: relative;

          &:before {
            position: absolute;
            left: 0;
            content: '/';
          }

          .views-row & {
            display: none;
          }
        }
      }
    }
  }
}

.custom-block {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 50px;
  border-top: 1px solid;
  padding-top: 16px;


  @include respond-to(xxl) {
    width: calc(100% + 25px);
    margin-bottom: 50px;
    padding: 12px;
    margin-left: -12px;
    margin-right: -12px;
    border-top: medium none;
  }

  &:hover {
    background-color: #fb4200;

    a {
      color: white;

      &:hover {
        color: black;
      }
    }
  }

  &:after {
    position: absolute;
    content: ' ';
    background-color: white;
    height: 50px;
    width: 16px;
    left: -10px;
    bottom: -50px;
  }

  .content {
    float: left;
    width: 86%;
    margin-top: 32px;
    margin-bottom: 20px;

    a {
      font-size: 30px;
      line-height: 36px;
      font-weight: 300;
      border-bottom: 1px solid;
    }
  }

  img {
    width: 100%;
  }

  label {
    display: initial;
    font-weight: 300;
    font-size: 16px;
    color: white;
    text-decoration: none;
    text-transform: none;
    background-color: black;
    padding: 6px 8px;
  }

  .left {
    float: left;
    width: 100%;

    @include respond-to(xxl) {
      width: 36%;
    }
  }

  .right {
    float: left;
    width: 100%;
    line-height: 0;

    @include respond-to(xxl) {
      width: 64%;
    }
  }

  &:last-child:after {
    display: none;
  }
}
