// Nav menu
//
// A "nav menu" is secondary, hierarchical navigation which can be displayed to the
// side or below the main content.
//
// Markup: nav-menu.twig
//
// Style guide: navigation.nav-menu

.nav-menu,
%nav-menu {

  &__item {
    list-style-image: image-url('navigation', 'nav-menu/leaf.svg');
    list-style-type: square;

    &.is-expanded,
    &--is-expanded {
      list-style-image: image-url('navigation', 'nav-menu/expanded.svg');
      list-style-type: circle;
    }

    &.is-collapsed,
    &--is-collapsed {
      list-style-image: image-url('navigation', 'nav-menu/collapsed.svg');
      list-style-type: disc;

      @include rtl() {
        list-style-image: image-url('navigation', 'nav-menu/collapsed-rtl.svg');
      }
    }
  }

  &__link {
    &.is-active,
    &--is-active {
      color: color(menu-active);
    }
  }
}

//
// Drupal selectors.
//

.menu__item {
  @extend %nav-menu__item;

  &.is-expanded {
    @extend %nav-menu__item--is-expanded;
  }

  &.is-collapsed {
    @extend %nav-menu__item--is-collapsed;
  }
}

// The active item in a Drupal menu.
.menu a.active {
  @extend %nav-menu__link--is-active;
}

#nexe-menu {
  position: fixed;
  display: none; // Change to NONE for production.
  box-sizing: border-box;
  top: 0;
  left: 0;
  background-color: color(orange);
  width: 100%;
  // Manual MENU items at BOTTOM of MENU => Qui som / Contacte
  // See  #block-block-5  at => sites/all/themes/custom/nexe_mygs/sass/navigation/nav-menu/_nav-menu.scss
  -webkit-transition: .5s linear;
  -webkit-transform-style: preserve-3d;
  animation-name: fadeout;
  animation-duration: .5s;
  transition-timing-function: ease-in;
  transition: .2s;
  z-index: 15;
  height: 100vh;

  .custom-menu-position {
    position: relative;
    background: url('/sites/all/themes/custom/nexe_mygs/assets/nexe_white.png') 20px 16px no-repeat transparent;
    background-size: 198px;
    width: 100%;
    margin: 0 auto;
    padding-top: 58px;
    height: 100vh;

    .region-navigation {
      float: left;
      width: 100%;

    }

    @include respond-to(xxl) {
      //margin-top: 30px;
      max-width: 1028px;
      padding-top: 16px;
      background-size: 198px;
      background-position: 24px 20px;

      a.menu-mobile {
        top: -6px;
        right: 4px;
      }
    }

    @include respond-to(xxxl) {
      max-width: 1378px;
      background-size: 300px;
    }
  }

  a.menu-mobile {
    right: 0;
    top: 6px;

    &:hover {
      background-color: transparent;
    }
  }

  &.menu-open {
    display: block;
    animation-name: fadein;
    animation-duration: .5s;
  }

  a {
    color: white;
    text-decoration: none;
    font-weight: 300;


    &.active-trail,
    &.active,
    &:hover {
      color: color(orange);
      background-color: white;
    }
  }

  .logged-in & {
    top: 64px;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

// Main menu HEADER
nav.main-menu {
  float: left;
  width: 100%;
  margin-top: 14px;

  ul.navbar {
    padding-left: 20px;

    li {
      float: left;
      width: 100%;
      font-size: 18px;
      line-height: 22px;
      font-weight: 300;
      margin-bottom: 8px;

      a {
        background-color: black;
        padding: 4px 8px;
      }
    }
  }

  @include respond-to(xxl) {

    ul.navbar {
      padding-left: 54px;

      li {
        margin-bottom: 10px;
      }
    }
  }

  @include respond-to(xxxl) {
    margin-top: 40px;

    ul.navbar {
      padding-left: 90px;

      li {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 14px;

        a {
          padding: 5px 12px;
        }
      }
    }
  }

}


// Button for fold/unfold the menu...
.menu-mobile {
  position: absolute;
  right: 0;
  top: 8px;
  z-index: 500;
  display: block;
  padding: 0;
  margin: 13px 14px;
  width: 33px;
  height: 30px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  background-color: transparent;
  color: color(orange);
  pointer-events: all;
  box-shadow: none !important;

  // CHILD (spans)
  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: color(orange);
    //border-radius: 3px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 10px;
    }

    &:nth-child(4) {
      top: 20px;
    }
  }


  @include respond-to(m) {
    position: relative;
    float: right;
  }

  @include respond-to(xxl) {

    span {
      height: 3px;
    }
  }

  @include respond-to(xxxl) {
    width: 50px;

    span {
      height: 5px;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 15px;
      }

      &:nth-child(4) {
        top: 30px;
      }
    }
  }

  &.open {
    color: white;

    span {
      background-color: white;

      &:nth-child(1) {
        top: 18px;
        width: 0;
        left: 50%;
      }

      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 18px;
        width: 0;
        left: 50%;
      }
    }
  }
}
