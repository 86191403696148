// Dependencies.
@import 'base/headings/headings';
@import 'components/clearfix/clearfix';


#header-container {
  width: 100%;
  z-index: 15;
  position: relative;

  &.sticky {
    // General RULES for mobile.
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: none;

    .header {
      -webkit-box-shadow: 0 7px 10px -6px rgba(0, 0, 0, 0.6);
      -moz-box-shadow: 0 7px 10px -6px rgba(0, 0, 0, 0.6);
      box-shadow: 0 7px 10px -6px rgba(0, 0, 0, 0.6);

      img {
        position: relative;
        z-index: 5;
        pointer-events: initial;
      }
    }

    // RULES for BREAKPOINT.
    @include respond-to(xxl) {
      .header {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        background-color: transparent;
        position: relative;

        img {
          -webkit-transform: rotate(-90deg);
          -moz-transform: rotate(-90deg);
          -o-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
          position: absolute;
          top: 92px;
          left: -60px;
          width: 180px;

          &.hide-desktop {
            display: none;
            visibility: hidden;
          }
        }
      }
    }

    @include respond-to(xxxl) {
      .header img {
        width: 300px;
        top: 134px;
        left: -107px;
      }
    }

    .logged-in & {
      top: 72px
    }
  }
}

.header,
%header {
  @extend %clearfix;

  position: relative;
  padding-bottom: 16px;
  background-color: white;
  //background-color: green;
  //min-height: 200px;
  margin: 0 auto;
  width: 100%;

  @include respond-to(xxl) {
    max-width: 1020px;
    padding-bottom: 12px;
  }


  @include respond-to(xxxl) {
    max-width: 1370px;
    padding-bottom: 14px;
  }

  // Wrapping link for logo.
  &__logo {
    float: left;
    margin: 20px 0 0 20px;
    padding: 0;

    @include rtl() {
      float: right;
      margin: 0 0 0 10px;
    }
  }

  // Logo image.
  &__logo-image {
    vertical-align: bottom;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    -ms-transition: -ms-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    width: 54%;

    @include respond-to(xxl) {
      width: 198px;
    }

    @include respond-to(xxxl) {
      width: 300px;
    }
  }

  // Wrapper for website name and slogan.
  &__name-and-slogan {
    float: left;
  }

  // The name of the website.
  &__site-name {
    @extend %h1;
    margin: 0;
  }

  // The link around the name of the website.
  &__site-link {
    &:link,
    &:visited {
      color: color(text);
      text-decoration: none;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  // The slogan (or tagline) of a website.
  &__site-slogan {
    margin: 0;
  }

  // The secondary menu (login, etc.)
  &__secondary-menu {
    float: right;

    @include rtl() {
      float: left;
    }
  }

  // Wrapper for any blocks placed in the header region.
  &__region {
    // Clear the logo.
    clear: both;
  }
}
