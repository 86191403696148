// Define a type layout (font-size and line-height).
//
// @param number|string $size
//   A size from the $font-size map or a px value.
// @param number $line-height
//   Multiple of line height to be used or px value to be converted.
// @param number|string $context
//   (optional) Only used if em is the $base-unit. The value of the
//   elements/parents font-size if it differs from $base-font-size.
//   Specified as a t-shirt size or value in px.
@mixin type-layout($size, $line-height, $context: $base-font-size) {
  @include font-size($size, $context);
  @include line-height($line-height, $size);
  @include typey-debug-grid($line-height, $size);
}
