// Define defaults (use this in the HTML element).
//
// @param number $size
//   (optional) The font-size. Use to set to anything other than $base-font-size.
// @param number $line-height
//   (optional) The line-height. Use to set to anything other than $base-line-height.
//   Set to a ratio when $line-height-as-ratio is true. Will default to $base-line-height-ratio.
//   Set to false if you do not want to change a ratio of line-height already set.
@mixin define-type-sizing($size: $base-font-size, $line-height: $base-line-height) {
  @if $base-unit == rem or $base-unit == em {
    font-size: $size / $browser-font-size * 100%;
  }
  @if $base-unit == px {
    font-size: $size;
  }

  @if $line-height != false {
    @if $line-height-method == "ratio" {
      @if $line-height == $base-line-height {
        $line-height: $base-line-height-ratio;
      }
      line-height: $line-height;
    }
    @else {
      @if $base-unit == rem or $base-unit == em {
        // In the html element, rem means relative to browser default font size; em means relative to html's font size.
        line-height: calculate-em-px($line-height, $size);
      }
      @if $base-unit == px {
        line-height: $line-height;
      }
    }
  }

  @if $auto-print-sizing == true {
    @if $base-unit == rem or $base-unit == em {
      @media print {
        font-size: $print-font-size;
      }
    }
    @else {
      @warn "As you are not using a relative base unit (rem or em) automatic print media sizing will not work. Set $auto-print-sizing to false to hide this warning"
    }
  }
}
