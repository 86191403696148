// GLOBALS for ALL CTypes
.title-bottom-container {
  float: left;
  width: 100%;

  .authors {
    float: left;
    width: 100%;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 8px;

    a {
      text-decoration: none;
      font-weight: 500;

      &:hover {
        color: color(orange);
      }
    }

    span.authors-info {
      padding-left: 5px;
      font-size: 20px;
      line-height: 30px;
      font-weight: 300;

    }
  }

  .field-collection-container {
    margin: 0;

    .field-item {
      margin-bottom: 0;
    }

    .action-links {
      display: none;
    }

    .field-collection-view .entity-field-collection-item {
      width: auto;
    }

    .field-collection-view-links {
      display: none;
    }
  }


  .custom-date {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    font-size: 14px;
    text-transform: uppercase;
  }
}

.field-name-body {
  float: left;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  margin-top: 30px;
  margin-bottom: 20px;
}

// BUILDER BLOCKS
.field-name-field-builder-blocks {

  .field-collection-view {
    padding: 0;
    margin: 0;
  }

  .field-name-field-title-bloc {

    span {
      display: initial;
      font-size: 26px;
      line-height: 30px;
      font-weight: 100;
      text-decoration: none;
      border-bottom: 1px solid grey;

      p {
        margin: 0;
      }
    }

    @include respond-to(xxl) {
      margin: 25px 0 15px;
    }
  }

  .field-name-field-text-bloc .field-item {
    float: left;
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
  }

  figcaption {
    font-size: 16px;
    line-height: 32px;
  }
}

// GLOBAL TABLE CLASSES
table {
  margin: 12px 0;

  p {
    margin-top: 0;
  }
}

table.imatge-esquerra {
  border: medium none;

  td {
    display: block;
    width: 100% !important;

    img {
      width: 100%;
    }
  }

  @include respond-to(m) {
    td {
      display: table-cell;
      width: 66% !important;

      &:nth-child(1) {
        border-top: 1px solid #6a6a6a;
        padding-right: 20px;
        padding-top: 40px;
        vertical-align: middle;
      }

      &:nth-child(2) {
        width: 33% !important;
        padding: 0 0 0 20px;
        font-size: 16px;
        vertical-align: middle;

        img {
          width: 100% !important;
        }
      }
    }
  }
}

table.imatge-dreta {
  border: medium none;

  td {
    display: block;
    width: 100% !important;

    img {
      width: 100%;
    }
  }

  @include respond-to(m) {
    td {
      display: table-cell;
      width: 66% !important;

      &:nth-child(1) {
        width: 33% !important;
        padding: 0 0 0 20px;
        font-size: 16px;
        vertical-align: middle;

        img {
          width: 100% !important;
        }
      }

      &:nth-child(2) {
        border-top: 1px solid #6a6a6a;
        padding-right: 20px;
        padding-top: 40px;
        vertical-align: middle;
      }
    }
  }
}

div.image-left {
  @include respond-to(xxl) {
    padding-right: 33%;

    img {
      padding-top: 20px;
      border-top: 1px solid;
    }
  }
}

div.image-right {
  @include respond-to(xxl) {
    padding-left: 33%;

    img {
      padding-top: 20px;
      border-top: 1px solid;
    }
  }
}

.custom-page-footer {

  @include respond-to(xxl) {
    position: relative;
    padding-left: 33%;
    padding-top: 26px;
    margin-bottom: 30px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      border-top: 1px solid;
      width: 66%;
    }
  }
}

div.left-highlighted,
div.right-highlighted {
  width: 100%;
  font-size: 26px;
  line-height: 30px;
  margin: 16px 10px;
  font-style: italic;
}


div.right-highlighted {
  float: right;

  @include respond-to(xxl) {
    float: right;
    width: 66%;
    border-top: 1px solid;
    padding-top: 40px;
    font-size: 26px;
    line-height: 30px;
    margin-left: 33%;
    margin-bottom: 40px;
  }
}

div.left-highlighted {
  float: left;

  @include respond-to(xxl) {
    width: 66%;
    border-top: 1px solid;
    padding-top: 40px;
    font-size: 26px;
    line-height: 30px;
    margin-right: 33%;
    margin-bottom: 40px;
  }
}

// Exception for CONTACTE
.page-node-44 table.image-left td:nth-child(2) {
  border-left: medium none;
}

// Custom BLOCKS
.block-block#block-block-6,
.block-block#block-block-7,
.block-block#block-block-8 {
  float: left;
  width: 100%;
  //padding-right: 15px;
  padding: 0 19px;

  h2,
  .block__title {
    margin-top: 10px;
    text-decoration: none;

    a {
      font-family: $ibmplex;
      font-size: 30px;
      line-height: 36px;
      font-weight: 300;
      text-decoration: none;
      border-bottom: 1px solid;
    }
  }

  img {
    width: 100%;
    margin-bottom: 15px;
  }

  &:hover {
    background-color: color(orange);

    a {
      color: white;
    }

    h2,
    .block__title {
      color: white;

      a {
        color: white;
        border-color: white;
      }
    }
  }
}

// TWO BLOCKS BUILDER
.field-name-field-two-blocs {
  float: left;
  width: 100%;

  > .field-items > .field-item {
    float: left;
    width: 50%;

    &.even {
      border-right: 1px solid;
      padding-right: 15px;
    }

    &.odd {
      padding-left: 15px;
    }

    .field-collection-view {
      margin: 0;
      padding: 0;

      img {
        width: 100%;
      }

      .field-name-field-title-bloc {
        float: left;
        width: 100%;

        .field-item {
          display: inline-block;
          font-size: 26px;
          line-height: 30px;
          font-weight: 100;
          text-decoration: none;
          border-bottom: 1px solid;

          p {
            margin: 0;
          }
        }
      }

      .field-name-field-info-author {
        float: left;
        width: 100%;
        min-height: 56px;
        font-size: 20px;
        line-height: 24px;
        font-weight: 300;
        margin-top: 4px;

        p {
          margin: 0;
        }
      }
    }
  }
}

.field-name-field-text-bloc {
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;

  p {
    font-size: 18px;
    line-height: 25px;
    font-weight: 300;
  }

  .field-item {

    div.page-footer {
      float: left;
      width: 50%;
      margin-left: 50%;
      border-top: 1px solid;
      padding-top: 20px;
      margin-top: 20px;

      > div {
        border: medium none;
        padding-top: 0;
        margin-top: 0;
      }

      p {
        font-size: 16px;
        line-height: 20px;
      }

      &:last-child {
        margin-bottom: 40px;
      }
    }
  }
}

// Custom for CONTACT
.node-type-webform {

  #title-wrapper h1 {
    margin-top: 10px;
    margin-left: 10px;
  }

  .node-webform {

    form.webform-client-form {
      float: left;
      width: 100%;

      @include respond-to(xxl) {
        width: 65%;
        padding-right: 15px;
      }
    }

    .field-name-body {
      float: left;
      width: 100%;

      @include respond-to(xxl) {
        width: 35%;
        padding-right: 15px;
        border-left: 1px solid;
        padding-left: 15px;
      }
    }

    .field-item h2 {
      font-family: $ibmplex;
      font-size: 30px;
      line-height: 36px;
      font-weight: 300;
      text-decoration: none;
      margin-top: 20px;
      border-bottom: 1px solid;
      display: inline-block;
    }

    &.node-45 {
      .field-name-body {

        @include respond-to(xxl) {
          margin-top: -75px;
          padding-bottom: 95px;
        }
      }
    }

    input.form-submit,
    [type='submit'] {
      display: block;
      width: 100%;
      background-color: black;
    }
  }
}


// Enquestes
.poll .vote-form {

  .choices {
    display: block;
  }
}

//Podcasts
.node-type-podcast {
  margin-bottom: 40px;

  .field-name-field-audio {
    float: left;
    width: 100%;
    text-align: center;

    .jp-audio {
      margin: 0 auto;
    }
  }
}

.node-cara-a-cara {

  > .field-name-body {

    p {
      margin-top: 0;
    }
  }

  .field-name-field-two-blocs {
    margin-top: 20px;

    .field-name-field-title-bloc {
      font-size: 24px;
      line-height: 28px;
    }
  }
}

// Cartes seniors
.node-type-cartes-seniors {

  // Header
  .title-bottom-container .field-name-field-autoria > .field-items > .field-item {
    position: relative;
    padding-right: 12px;
    margin-right: 8px;

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      content: ' | ';
    }

    &:last-child:before {
      content: '';
    }
  }

  // Inner blocks
  .field-name-field-two-blocs > .field-items > .field-item .field-collection-view .field-name-field-image {
    width: 100%;

    img {
      width: 50%;
    }
  }

  .main-article .field-name-field-autoria {
    margin-top: 80px;

    > .field-items > .field-item {
      padding-right: 12px;
      margin-right: 8px;

      &:before {
        content: '|';
      }

      &:last-child:before {
        content: '';
      }
    }
  }
}

// Ressenya
.node-type-ressenya,
.node-type-revista-en-paper {

  .field-name-field-image {
    float: left;
    width: 60%;
    padding-right: 15px;
  }

  .field-name-body {
    float: left;
    width: 40%;
    margin-top: 0;
    padding-left: 15px;
    border-left: 1px solid;

    p {
      margin: 0 0 1.5rem;
    }
  }

  .field-name-field-builder-blocks .field-collection-view {
    margin: 0;
  }
}

// Espécifico REVISTA
.node-type-revista-en-paper {
  .field-name-body {
    padding-left: 0;
    border: medium none;
  }
}

// Reflexions Cooperatives
.node-type-reflexions-cooperatives {

  .field-name-body {
    margin: 0;

    p {
      margin: 0 0 1.5rem;
    }
  }

  .field-name-field-builder-blocks .field-collection-view {
    margin: 0;
  }
}

// Actualitat (Full NODE VIEW)
.node-type-reflexions-cooperatives,
.node-type-reportatge,
.node-type-entrevistes,
.node-type-article {

  .field-name-body {
    width: 100%;
    border-top: 1px solid;
    margin-top: 20px;

    p {
      font-size: 26px;
      line-height: 30px;

      &.head-non-highlighted {
        font-size: 18px;
        line-height: 22px;
      }
    }

    @include respond-to(xxl) {
      padding-top: 16px;

      p {
        padding-right: 30%;
        padding-left: 3%;

        &.head-non-highlighted {
          padding: 0;
        }
      }
    }
  }
}

// Entevistas (bloques cruzados)
.node-type-entrevistes .field-name-field-builder-blocks {
  margin-top: 30px;

  > .field-items > .field-item {
    width: 100%;
    margin: 0;
  }

  @include respond-to(xxl) {
    margin-top: 12px;

    > .field-items > .field-item {
      width: 67%;
      margin-left: 33%;
      border-top: 1px solid;

      &.even {
        //float: right;
        margin-left: 0;
        margin-right: 33%;
      }

      .field-name-field-title-bloc span {
        border-bottom: medium none;
      }
    }
  }
}

// Agenda (Full NODE VIEW)
.node-type-agenda {

  .node-agenda {
    float: left;
    width: 100%;
    margin-top: 20px;
  }

  .node-date-wrapper {
    float: left;
    width: 100%;

    @include respond-to(xxl) {
      width: 34%;
      padding-right: 15px;
    }
  }

  .content-wrapper {
    float: left;
    width: 100%;

    .field-name-field-data-activitat {
      margin-top: 20px;

      .field-label,
      .date-display-single {
        font-size: 20px;
        line-height: 24px;
        font-weight: 300;
      }
    }

    .field-name-field-enlla-publi {
      padding-bottom: 20px;
      border-bottom: 1px solid;
      margin-bottom: 20px;

      .field-label {
        font-size: 20px;
        line-height: 24px;
        font-weight: 300;
      }

      .field-item a {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
      }
    }

    .field-name-body {
      margin-top: 0;
    }

    @include respond-to(xxl) {
      width: 66%;
      padding-left: 15px;


      .field-name-field-data-activitat {
        margin-top: 0;
      }
    }
  }
}

// SEARCH PAGE
.page-search main {

  // HIDE NON-USED options
  ul.tabs {
    display: none;
  }

  /**/
  // FORM Settings
  .form-item-keys {
    width: 50%;
  }

  input[type='text'] {
    border: 1px solid;
  }

  input[type='submit'] {
    color: white;
    background-color: black;
    padding: 10px 14px;
    border: medium none;
    width: 50%;

    &:hover {
      background-color: color(orange);
    }
  }

  fieldset.search-advanced {
    margin-top: 20px;

    a {
      font-size: 22px;
      line-height: 26px;
      color: color(orange);
    }


    .action {
      width: 100%;
    }

    .fieldset-wrapper {
      float: left;
      width: 100%;

      // Type Selectors (checkboxes)
      .criterion {
        width: 34%;
        margin-right: 6%;

        &:nth-child(2) {
          width: 60%;
          margin-right: 0;
        }
      }

      // Checkboxes wrapper
      .form-checkboxes {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        height: 164px;

        .form-item {
          width: 33.333%;
          margin: 4px 0;
        }
      }

    }

  }

  // RESULTS !!!
  ol.search-results {

    p {
      font-family: $ibmplex;
      font-weight: 300;
      font-size: 18px;
      line-height: 24px;
    }

    li.search-result {
      float: left;
      width: 100%;

      .result-image-box {
        float: left;
        width: 10%;
        padding-right: 2%;
        border-right: 1px solid;

        .dictionari-box {
          color: white;
          width: 95px;
          height: 95px;
          text-align: center;
          background-color: #28dce0;
          padding-top: 32px;
          font-size: 40px;

          a {
            color: white;
            text-decoration: none;
          }

          &:hover {
            a {
              color: color(orange);
            }
          }

        }

        .event-date-container {
          height: 95px;

          .event-day {
            font-size: 24px;
            line-height: 26px;
            margin-top: 22px;
          }

          .event-month {
            font-size: 30px;
            line-height: 32px;
          }

          &:hover {
            a {
              text-decoration: underline;
            }
          }
        }
      }

      .result-content-box {
        float: left;
        width: 88%;
        padding-left: 20px;

        .type-container {
          margin-bottom: 8px;

          span a {
            font-family: $ibmplex;
            font-size: 16px;
            line-height: 16px;
            font-weight: 300;
            padding: 4px 8px;
            background-color: black;
            color: white;

            &:hover {
              background-color: color(orange);
              //border: 1px solid color(orange);
            }
          }
        }

        h3 {
          float: left;
          width: 100%;
          margin-top: 0;
          font-weight: 100;

          a {
            font-family: $ibmplex;
            font-weight: 100;
            font-size: 30px;
            padding: 6px 0;
            line-height: 36px;
            border-bottom: 1px solid grey;
          }
        }

        .search-snippet-info {
          padding: 0;
        }
      }
    }
  }
}
