.view-id-nexe_home {
  float: left;
  width: 100%;


  @include respond-to(xxl) {
    margin-bottom: 60px;
    border-right: 1px solid grey;
  }

  .views-row {

    .node {
      position: relative;
      float: left;
      width: 100%;
      padding: 0 19px 38px 19px;
      margin-bottom: 6%;
      border-bottom: 1px solid;
      position: relative;
      box-sizing: border-box;

      &:after {
        position: absolute;
        content: ' ';
        width: 40px;
        height: 40px;
        background-color: white;
        bottom: -40px;
        right: -20px;
      }

      .content-wrapper {
        position: relative;
        height: auto;
        padding-top: 6px;

        .share-wrapper {

          .block-share-opener {
            top: 0;
          }

          .share-buttons {
            top: -4px;
          }
        }
      }

      .block-share-opener {
        background: url('/sites/all/themes/custom/nexe_mygs/assets/share_black.png') no-repeat 0 0 transparent;
      }

      @include respond-to(m) {
        width: 50%;
        border-bottom: medium none;
        border-right: 1px solid; // CHANGING BORDERS (comment all)

        &:nth-child(2n) {
          border-right: medium none;
        }

        .content-wrapper {
          height: 280px;
          padding-top: 14px;
        }
      }

      // Pasamos a 1024
      @include respond-to(xxl) {
        width: 33.333%;
        padding: 0 19px;
        border-left: 1px solid grey;
        border-right: medium none !important;
        margin-bottom: 4%;

        .content-wrapper {
          height: 178px;
        }
      }

      @include respond-to(xxxl) {

        .content-wrapper {
          height: 148px;
        }

        .wrapper-titol-llistats {
          font-size: 30px;
          line-height: 36px;
        }
      }

      .wrapper-titol-llistats {
        float: left;
        width: 100%;
        font-size: 22px;
        line-height: 27px;
        font-weight: 100;
        padding: 6px 0;

        margin-top: 12px;
        margin-bottom: 8px;

        a {
          font-weight: 100;
          text-decoration: none;
          border-bottom: 1px solid;
        }
      }

      .field-authors-link {
        float: left;
        width: 100%;
        font-size: 15px;
        line-height: 18px;
        font-weight: 300;

        a {
          text-decoration: none;
          border-bottom: 1px solid;
        }
      }

      .field-name-field-text-lliure-autor {
        display: none;
        font-size: 15px;
        line-height: 20px;
        font-weight: 300;

        @include respond-to(xl) {
          display: none;
        }

      }

      .field-name-field-subtitle {
        float: left;
        width: 100%;
        font-size: 20px;
        line-height: 24px;
        font-weight: 300;
        margin-bottom: 8px;

        p {
          margin: 0;
        }
      }


      .destination {
        float: left;
        margin-right: 8px;

        a {
          font-weight: 300;
          font-size: 12px;
          line-height: 14px;
          color: white;
          background-color: black;
          padding: 6px 8px 4px 8px;
          text-decoration: none;
          //text-transform: capitalize;

          @include respond-to(xxxl) {
            font-size: 16px;
            line-height: 19px;
          }

          &:hover {
            color: black;
            background-color: white;
          }
        }
      }

      .field-name-field-etiqueta-superior {
        a {
          box-sizing: border-box;
          font-weight: 300;
          font-size: 12px;
          line-height: 14px;
          border: 1px solid;
          padding: 5px 8px 3px 8px;

          @include respond-to(xxxl) {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }

      .entradeta_llistat {
        display: none;
      }
      @include respond-to(xxl) {
        width: 25%;

        &.presentation-dues {
          width: 50%;
        }

        // Usa 3 "barras" verticales
        &.presentation-tres {
          width: 75%;

          .field-name-field-image {
            width: 65%;
            padding-right: 2.5%;
            border-right: 1px solid grey;
          }
          .entradeta_llistat {
            display: block;
            float: left;
            width: 35%;
            padding-left: 2.5%;

            p {
              margin: 0;
              font-size: 18px;
            }
          }

        }

        &.highlighted-grey {
          background-color: darkgrey;
          color: white;

          a {
            color: white;
          }
        }

        &.highlighted-red {
          background-color: color(orange);
          color: white;

          a {
            color: white;
          }

          &:hover {
            background-color: darkgrey;
          }
        }

        &:hover {
          color: white;
          background-color: color(orange);

          a {
            color: white;
          }

          .block-share-opener {
            background: url('/sites/all/themes/custom/nexe_mygs/assets/share_white.png') no-repeat 0 0 transparent;
          }

          .wrapper-titol-llistats a {
            border-color: white;
          }

          .field-name-field-etiqueta-superior {
            a:hover {
              color: black;
              background-color: white;
              border: medium none;
            }
          }
        }
      }

      // Pasamos a FULL
      @include respond-to(xxxl) {
        margin-bottom: 6%;

        .field-authors-link {
          margin-bottom: 6px;
        }
      }

      &.node-publicitat {

        &:hover {
          color: black;
          background: none;

          a {
            color: black;
          }
        }

        // PUBLI
        .publi-only-vertical,
        .publi-only-square,
        .publi-only-lateral,
        .publi-only-horizontal {
          display: none;
        }

        &.presentation-una {
          .publi-only-vertical {
            display: block;
          }
        }

        &.presentation-dues {
          .publi-only-square {
            display: block;
          }
        }

        .custom-block-title {
          font-family: $ibmplex;
          padding-top: 4px;
          padding-bottom: 5px;
          font-size: 12px;
          line-height: 14px;
        }

        @include respond-to(xxxl) {

          .custom-block-title {
            font-size: 16px;
            line-height: 19px;
            margin-top: 10px;
            margin-bottom: 14px;
          }

          .publi-only-vertical {
            .field-name-field-image img {
              height: 434px;
            }
          }
        }
      }


      &.node-cara-a-cara,
      &.node-opinio {

        .field-authors-link .link-item {
          font-weight: 700;
        }

        .field-name-field-autoria {
          font-weight: 700;
        }
      }

      &.node-agenda {
        .node-date {
          display: none;

          @include respond-to(xl) {
            display: block;
          }
        }
      }
    }

    .node-date {
      float: left;
      width: 100%;
      font-size: 14px;
      font-weight: 300;
      text-transform: uppercase;
      padding: 10px 0;
    }

    .dictionary-cointainer {
      float: left;
      width: 100%;
      height: 290px;
      background-color: #28DCE0;
      text-align: center;
      padding-top: 84px;
      font-size: 96px;
      line-height: 110px;
      font-weight: 300;

      a {
        text-decoration: none;
        font-weight: 100;
      }

      @include respond-to(xxl) {
        padding-top: 50px;
        height: 188px;
      }

      @include respond-to(xxxl) {
        height: 262px;
        padding-top: 80px;
      }
    }

    .field-name-field-image {
      float: left;
      width: 100%;
      margin-bottom: 0;

      figure {
        //margin-bottom: 4px;
      }

      img {
        width: 100%;
        margin-bottom: 0;
      }
    }

    // View PARENT of NODE
    @include respond-to(xxl) {
      &:nth-child(3n) {

        .node {
          border-right: 1px solid grey;
        }
      }
    }
  }


  &.view-display-id-four_items_home {

    .views-row .field-name-field-image {
      float: left;
      width: 100%;
    }

    figcaption {
      display: none;
      height: 0;
      visibility: hidden;
    }

    // Desde 1024 en 4 COL.
    @include respond-to(xxl) {

      .views-row .node {
        width: 25%; // 4 COL
        padding-bottom: 8px;

      }

      .views-row:nth-child(3n) {
        .node {
          border-right: medium none;
        }
      }

      .views-row:nth-child(4n) {
        .node {
          border-right: 1px solid grey;
        }
      }
    }


    .views-row .content-wrapper {

      @include respond-to(xl) {
        height: 220px;
        padding-top: 10px;
      }

      @include respond-to(xxl) {
        height: 184px;
        padding-top: 10px;
      }

      @include respond-to(xxxl) {
        height: 196px;
        padding-top: 14px;
      }
    }
  }
}
