.footer-container {
  margin: 0 auto;
  //background-color: white;
  width: 100%;
  //background: red;
  min-height: 322px;

  @include respond-to(xl) {
    max-width: 834px;
  }

  @include respond-to(xxl) {
    max-width: 904px;
  }

  @include respond-to(xxxl) {
    max-width: 1196px;
  }

  .footer-normalize {
    float: left;
    width: 100%;
    background-color: white;
    padding: 0 2%;
    @include respond-to(xxl) {
      padding: 0;
      margin-top: 60px;
    }

  }


  footer {
    width: 100%;
    margin: 0 auto;

    @include respond-to(xxl) {
      .region {
        min-height: 384px;
      }
    }

    @include respond-to(xxxl) {
      .region {
        min-height: 384px;
      }
    }

    // Footer I
    .region-footer-one {
      float: left;
      width: 100%;
      display: none;
      box-sizing: border-box;

      ul {
        list-style: none;
        padding: 0;
        margin: 12px 0;

        li {
          list-style: none;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 12px;

          a {
            padding: 4px 6px;
            background-color: black;
            color: white;
            text-decoration: none;
            font-weight: 300;

            &:hover,
            &.active,
            &.active-trail {
              background-color: color(orange);
            }
          }
        }
      }

      // Desktop and so on...
      @include respond-to(xxl) {
        display: block;
        width: 25%;
        padding: 0 18px;
        border-left: 1px solid dimgrey;
      }

    }

    // Footer II
    .region-footer-two {
      float: left;
      width: 100%;
      box-sizing: border-box;

      img {
        width: 100%;
        margin-top: 12px;
      }

      .footer-address {
        font-family: $ibmplex;
        margin-top: 60px;

        p {
          font-weight: 300;
        }
      }

      .footer-legal {
        font-family: $ibmplex;
        position: relative;
        bottom: 0;

        a {
          font-family: $ibmplex;
        }
      }

      @include respond-to(xxl) {
        border-left: 1px solid dimgrey;
        width: 25%;
        padding: 0 18px;
        >div {
          float: left;
          width: 100%;

          &:first-child {
            margin-bottom: 40px;
          }
        }

        img {
          width: 100%;
        }
      }
    }

    // Footer III
    .region-footer-three {
      display: none;

      // Desktop and so on...
      @include respond-to(xxl) {
        float: left;
        box-sizing: border-box;
        margin-bottom: 6%;
        font-weight: 300;
        display: block;
        width: 50%;
        margin-top: 0;
        height: 310px;
        padding: 0 18px;
        border-left: 1px solid dimgrey;
        border-right: 1px solid dimgrey;

      }
    }
  }

  // Outside BOLCKS in case we move on footer postiion later...
  h2.block__title {
    display: inline-block;
    font-size: 12px;
    line-height: 14px;
    text-decoration: none;
    padding: 6px 8px;
    width: auto;
    margin: 12px 0;
    border: 1px solid;
    font-weight: 300;

    @include respond-to(xxxl) {
      font-size: 16px;
      line-height: 19px;
    }
  }

  .webform-client-form h2 {
    float: left;
    width: 100%;
    margin: 12px 0;
  }

  .social-icons-wrapper {
    margin-top: 16px;

    ul.social-icons-items {
      list-style: none;
      padding: 0;
      margin: 6px 0;

      li {
        float: left;
        overflow: hidden;
        margin-right: 6px;
        width: 34px;
        height: 34px;

        &.ico-facebook {
          background: url("/sites/all/themes/custom/nexe_mygs/assets/icon_fb_black.png") no-repeat 0 0 transparent;

          &:hover {
            background: url("/sites/all/themes/custom/nexe_mygs/assets/icon_fb_orange.png") no-repeat 0 0 transparent;
          }
        }

        &.ico-twitter {
          background: url("/sites/all/themes/custom/nexe_mygs/assets/icon_tw_black.png") no-repeat 0 0 transparent;

          &:hover {
            background: url("/sites/all/themes/custom/nexe_mygs/assets/icon_tw_orange.png") no-repeat 0 0 transparent;
          }
        }

        &.ico-linkedin {
          background: url("/sites/all/themes/custom/nexe_mygs/assets/icon_ln_black.png") no-repeat 0 0 transparent;

          &:hover {
            background: url("/sites/all/themes/custom/nexe_mygs/assets/icon_ln_orange.png") no-repeat 0 0 transparent;
          }
        }

        &.ico-youtube {
          background: url("/sites/all/themes/custom/nexe_mygs/assets/icon_ytb_black.png") no-repeat 0 0 transparent;

          &:hover {
            background: url("/sites/all/themes/custom/nexe_mygs/assets/icon_ytb_orange.png") no-repeat 0 0 transparent;
          }
        }

        &.ico-instagram {
          background: url("/sites/all/themes/custom/nexe_mygs/assets/icon_insta_black.png") no-repeat 0 0 transparent;

          &:hover {
            background: url("/sites/all/themes/custom/nexe_mygs/assets/icon_insta_orange.png") no-repeat 0 0 transparent;
          }
        }

        // LINK inside this list elements...
        a {
          color: transparent;
          text-indent: 9999px;
          padding: 2px 28px 10px 0;
        }
      }
    }
  }

}

.region-bottom {

  margin: 0 auto;
  width: 100%;
  min-height: 322px;
  padding-top: 40px;

  >.block {
    float: left;
    width: 100%;

    h2.custom-block-title {
      font-weight: 300;
      font-size: 1rem;
      line-height: 0.4rem;
    }

    >div {
      float: left;
      width: 50%;
      border-left: 1px solid;
      padding-top: 15px;
      padding-left: 20px;
      min-height: 100px;

      img {
        width: 90%;
      }

      &.contextual-links-wrapper {
        border: medium none;
      }

      &.bottom-two {
        padding-top: 26px;
      }

      &.bottom-four {
        padding-top: 20px;

        img {
          width: 60%;
        }
      }
    }
  }

  // Container region breakpoints.
  @include respond-to(xl) {
    max-width: 834px;
    > .block > div {
      width: 50%;
      min-height: 120px;
    }
  }

  @include respond-to(xxl) {
    max-width: 904px;

    > .block > div {
      min-height: 80px;
      width: 20%;

      &.bottom-five {
        border-right: 1px solid;
      }
    }
  }

  @include respond-to(xxxl) {
    max-width: 1196px;
  }
}
