// Home BLOCKs
.view-main-home {

  .views-row {
    position: relative;
    border-bottom: 1px solid black;
    padding-top: 10%;
    box-sizing: border-box;
    width: 100%;

    &:hover {
      background-color: color(orange);
      color: white !important;

      a {
        color: white !important;
      }
    }

    @include respond-to(m) {
      float: left;
      width: 50%;
      padding-top: 0;
      padding-right: 1%;
      border-bottom: medium none;

      &.views-row-even {
        padding-left: 1%;
        padding-right: 0;
      }
    }

    @include respond-to(xxl) {
      float: left;
      padding: 0 1% 0 1%;
      border-left: 1px solid grey;
      border-bottom: medium none;
      margin-bottom: 6%;

      &:nth-child(3n) {
        border-right: 1px solid grey;
      }

      &.views-row-even {
        margin-right: initial;
        padding-right: 1%;
      }
      &.cc-una {
        width: 25%;
      }

    }

  }

  // SHARE
  .views-field-nothing-1 {
    position: absolute;
    top: 2%;
    right: 2%;

    @include respond-to(m) {
      display: none;
      // HERE whe MUST add RULES to show on "mouse-over"
    }
  }

  .views-field-type {
    padding-top: 14px;

    .field-content {
      background-color: black;
      color: white;
      font-weight: 300;
      padding: 5px 8px 3px 8px;

      a {
        color: white;
        text-decoration: none;
        border-bottom: 1px solid;
        text-transform: capitalize;

        &:hover {
          background-color: color(orange);
        }
      }
    }
  }

  // Wrapper for TITLE and subtitle.
  .views-field-nothing-2 {
    float: left;
    width: 100%;

    @include respond-to(xxl) {
      height: 170px;
    }

    @include respond-to(xxxl) {
      height: 154px;
    }

  }

  .field-titol-llistats {
    float: left;
    width: 100%;
    font-size: 30px;
    font-weight: 100;
    padding: 6px 0;
    line-height: 36px;
    margin-bottom: 8px;

    a {
      color: black;
      font-weight: 100;
      text-decoration: none;
      border-bottom: 1px solid dimgrey;
    }
  }

  .field-autoria-llistats {
    float: left;
    width: 100%;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 8px;
  }

  .field-sub-titol-llistats {
    float: left;
    width: 100%;
    font-size: 20px;
    font-weight: 300;

    p {
      margin: 0;
    }
  }

  // Veure mes (link)
  .views-field-nothing {
    padding: 4% 0;
    font-size: 16px;
    text-transform: uppercase;

    a {
      text-decoration: none;
      border-bottom: 1px solid dimgrey;
    }

    @include respond-to(m) {
      display: none;
      // HERE whe MUST add RULES to show on "mouse-over"
    }
  }

  .views-field-created {
    float: left;
    width: 100%;
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    padding: 10px 0;
  }

  .views-field-field-image {
    padding-bottom: 8%;
    width: 100%;

    img {
      width: 100%;
    }

    @include respond-to(xxl) {
      padding: 0;
    }
  }


  // 4 BLOCS (bottom page)
  &.view-display-id-home_quatre_items {
    .views-row {
      width: 25%;

      &:nth-child(3n) {
        border-right: medium none;
      }

      &:last-child {
        border-right: 1px solid;
      }
    }
  }

}

// Bloque Te puede interesar (OLD)
#block-views-llistats-relacionats-actualitat {
  float: left;
  width: 952px; // TODO => ADJUST Mobile and so on (Responsive)
  margin-left: -12px;
  //background-color: red; // TODO => change to red for testing !!!
  margin-bottom: 44px;

  h2 {
    margin-left: -20px;
    padding-left: 40px;
    background-color: white;
  }
}

// TE PUEDEINTERESAR (RELACIONATS) --- BLOC for ALL types
// WE MUST float ALL and EACH of titles
h2.block__title.relacionats {
  float: left;
  width: 100%;
  background-color: white;
  margin-left: -15px;
  padding-left: 15px;
  font-size: 26px;
  font-weight: 300;
  line-height: 30px;
  padding-top: 30px;
}

// And now WIEWS for aLL
.view-nexe-relacionats {
  float: left;
  width: calc(100% + 25px);
  margin-bottom: 60px;
  //background: red;
  margin-left: -12px;

  .views-row {

    .node {
      width: 100%;
      float: left;
      padding: 0 0 20px;
      border-bottom: 1px solid;
      margin-bottom: 20px;

      .content-wrapper {
        position: relative;
        height: 224px;
        padding-top: 14px;

        .share-wrapper {
          top: 0;
          right: 0;
        }
      }

      .wrapper-titol-llistats {
        float: left;
        width: 100%;
        font-size: 22px;
        line-height: 27px;
        font-weight: 100;
        padding: 6px 0;
        margin-top: 12px;
        margin-bottom: 8px;

        a {
          text-decoration: none;
        }

        @include respond-to(xxxl) {
          font-size: 30px;
          line-height: 36px;
        }
      }

      .field-authors-link {
        float: left;
        width: 100%;
        font-size: 14px;
        line-height: 14px;
        font-weight: 700;

        a {
          font-weight: 700;
          text-decoration: none;
        }

        @include respond-to(xxxl) {
          margin-bottom: 8px;
        }
      }

      .destination a {
        color: white;
        background-color: black;
        padding: 6px 8px 4px 8px;
        text-decoration: none;
      }

      @include respond-to(xxl) {
        width: 33.333%;
        border-bottom: medium none;
        border-right: 1px solid black;
        padding: 0 15px;

        &:nth-child(1) {
          .node {
            padding-left: 0;
          }
        }

        &:nth-child(3) {
          .node {
            border-right: medium none;
            padding-right: 0;
          }
        }
      }

      &:hover {
        .block-share-opener {
          display: block;
          background: url('/sites/all/themes/custom/nexe_mygs/assets/share_white.png') no-repeat 0 0 transparent;

          &:hover {
            background: url('/sites/all/themes/custom/nexe_mygs/assets/share_black.png') no-repeat 0 0 transparent;
          }
        }
      }
    }

    .node-date {
      float: left;
      width: 100%;
      font-size: 14px;
      font-weight: 300;
      text-transform: uppercase;
      padding: 10px 0;
    }

    .dictionary-cointainer {
      float: left;
      width: 100%;
      height: 290px;
      background-color: #28DCE0;
      text-align: center;
      padding-top: 84px;
      font-size: 96px;
      line-height: 110px;
      font-weight: 300;

      a {
        text-decoration: none;
      }
    }

    .field-name-field-image {
      float: left;
      width: 100%;
      padding: 0;

      img {
        width: 100%;
      }

      @include respond-to(xxxl) {
        height: 162px;
        overflow: hidden;
      }
    }
  }


  figcaption {
    display: none;
    height: 0;
    visibility: hidden;
  }

  .views-row:nth-child(3n) {
    .node {
      border-right: medium none;
    }
  }

  .views-row:nth-child(4n) {
    .node {
      border-right: 1px solid grey;
    }
  }

  .content-wrapper {
    height: 280px;
    padding-top: 14px;
  }

}


// RECOMENDED
#block-mygs-nexe-recommended {
  padding: 0 12px;

  h2.block__title {
    float: left;
    width: auto;
    border: 1px solid;
    margin: 10px 0;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    padding: 6px 8px;
    text-decoration: none;

    @include respond-to(xxxl) {
      font-size: 16px;
      line-height: 19px;
    }
  }

  h2.header-recommended-block {
    display: none;
    height: 0;
  }

  .view-display-id-recomanats {
    float: left;
    width: 100%;
    margin-top: 40px;

    .views-row {
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid;
    }

    .views-field-type {
      padding-top: 4px;
      margin-bottom: 18px;
      font-size: 16px;
      line-height: 16px;

      @include respond-to(xxl) {
        margin-bottom: 8px;
      }

      .field-content {
        background-color: black;
        color: white;
        padding: 6px 8px;
        font-size: 12px;
        line-height: 14px;

        @include respond-to(xxxl) {
          font-size: 16px;
          line-height: 19px;
        }

        a {
          font-weight: 300;
          color: white;
          text-decoration: none;
          text-transform: none;
        }

        &:hover {
          background-color: color(orange);
        }
      }
    }

    .views-field-field-titol-llistats {
      margin-top: 20px;
      font-size: 20px;
      line-height: 24px;

      a {
        font-weight: 300;
        border-bottom: 1px solid;

        &:hover {
          color: color(orange);
        }
      }
    }
  }
}

// Bloque PUBLI LATERAL
.view-display-id-publi_lateral {
  float: left;
  width: 100%;
  margin-top: 100px;
  margin-bottom: 20px;
  padding: 0 12px;

  img {
    float: left;
    width: 100%;
  }

  .publi-only-vertical,
  .publi-only-horizontal,
  .publi-only-square {
    display: none;
    visibility: hidden;
    height: 0;
    overflow: hidden;
  }
}

// Bloc AGENDA LATERAL
#block-views-nexe-llistats-agenda-lateral {
  float: left;
  width: 100%;

  .view-display-id-agenda_lateral {
    float: left;
    width: 100%;
    margin-bottom: 10px;

    .views-row {

      .node.node-agenda {
        float: left;
        width: 100%;
        margin-bottom: 0;
        border-bottom: 1px solid;
        padding-bottom: 50px;

        .content-wrapper {
          position: relative;
        }

        .destination a {
          font-size: 12px;
          line-height: 14px;
          font-weight: 300;
          color: white;
          background-color: black;
          padding: 6px 8px;
          text-decoration: none;

          @include respond-to(xxxl) {
            font-size: 16px;
            line-height: 19px;
          }
        }


        @include respond-to(xxl) {
          padding-bottom: 4px;
          border: medium none;

          .content-wrapper {
            height: 280px;
            padding-top: 14px;
            width: 100%;
          }

          &:hover {
            .destination a:hover {
              background-color: white;
              color: black;
            }

            .wrapper-titol-llistats a {
              border-color: white;
            }
          }
        }
      }

      .node-date {
        float: left;
        width: 100%;
        font-size: 14px;
        font-weight: 300;
        text-transform: uppercase;
        padding: 10px 0;
      }
    }
  }
}


// LANDINGS !!!
.page-views {

  #title-wrapper.sticky .title-position .title-width {
    height: 70px;
  }

  h1#main-title {
    font-size: 16px;
    line-height: 19px;
    color: white;
    background-color: color(orange);
    text-decoration: none;
    padding: 6px 8px;
    width: auto;
    float: left;
    margin-top: 12px;
  }

  // EXPOSED FORM
  .views-exposed-form {

    /* Until future, we disallow by CSS */
    /* To enable, JUST delete until next comment */
    display: none;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    /* To enable, JUST delete until HERE */
    position: relative;
    float: left;
    width: 100%;
    margin-bottom: 40px;

    // Search TITLE
    .views-widget-filter-title {
      float: right;
      width: 60%;

      label {
        display: none;
      }
    }


    // Sort
    .views-exposed-widget {

      &.views-submit-button {
        float: left;
        width: 37%;
        margin-right: 3%;
        border-bottom: 1px solid black;
        padding: 0;

        fieldset {
          border: medium none;
          padding: 8px 0;
          margin: 4px 0;

          &.collapsible {
            cursor: pointer;
            background: url("/sites/all/themes/custom/nexe_mygs/assets/collapsible.png") no-repeat 82% 10px transparent;
            padding: 0 6px;
            margin: 0;
            height: 36px;

            &:hover {
              background: url("/sites/all/themes/custom/nexe_mygs/assets/collapsible_orange.png") no-repeat 96% 10px transparent;
            }

            @include respond-to(xxxl) {
              background-position: 96% 10px;
            }
          }

          // Collapsible BOX content
          .fieldset-wrapper {
            float: left;
            background-color: white;
            border-left: 1px solid;
            border-right: 1px solid;
            border-bottom: 1px solid;
            padding: 0 14px 14px 14px;
            margin-top: 9px;
            width: 326px;

            label[for="edit-sort-bef-combine"] {
              color: transparent;
              height: 10px;
            }
          }
        }

        legend {
          float: left;
          margin-top: 6px;
          padding: 0;
        }
      }
    }


    .summary {
      display: none;
    }

    a.fieldset-title {
      text-decoration: none;
      padding: 0 184px 0 0;
    }

    .collapsible .fieldset-legend {
      width: 300px;
      background: none;
    }

    // Input "Cercar per titol"
    input[type="text"] {
      padding: 6px 10px;
    }

    // Button
    input[type="submit"] {
      position: absolute;
      margin: 0;
      top: 0;
      right: 6px;
      border: medium none;
      width: 40px;
      height: 40px;
      text-indent: 999px;
      background: url("/sites/all/themes/custom/nexe_mygs/assets/search.png") no-repeat 0 10px transparent;

      &:hover {
        background: url("/sites/all/themes/custom/nexe_mygs/assets/search_orange.png") no-repeat 0 10px transparent;
      }
    }

  }

  // CONTENIDO // RESULTADOS BUSQUEDA !!
  .landing {
    float: left;
    width: 100%;

    //background-color: #5c5c5b;
    @include respond-to(xl) {
      width: calc(100% + 26px);
      margin-left: -13px;
      margin-top: 25px;
    }

    .views-row {
      float: left;
      width: 100%;

      // Espacificaciones generales para los hijos...
      .node {
        float: left;
        width: 100%;
        border-bottom: 1px solid;
        margin-bottom: 20px;

        .content-wrapper {
          position: relative;

          .share-wrapper {
            right: 0;
            top: 0;

            .block-share-opener {
              position: absolute;
              top: 0;
              right: 0;
              height: 30px;
              width: 30px;
              cursor: pointer;
              background: url(/sites/all/themes/custom/nexe_mygs/assets/share_black.png) no-repeat 0 0 transparent;
            }

            .share-buttons {
              //display: block;
              width: 136px;
              right: 32px;
              top: -8px
            }
          }
        }

        .destination {
          float: left;
          margin-right: 8px;

          a {
            font-weight: 300;
            color: white;
            background-color: black;
            padding: 6px 8px 4px 8px;
            text-decoration: none;
            font-size: 12px;
            line-height: 14px;

            @include respond-to(xxxl) {
              font-size: 16px;
              line-height: 19px;
            }

            &:hover {
              color: black;
              background-color: white;
            }
          }
        }

        .field-name-field-etiqueta-superior {
          float: left;
          padding-left: 6px;

          a {
            box-sizing: border-box;
            font-weight: 300;
            font-size: 12px;
            line-height: 14px;
            border: 1px solid;
            padding: 5px 8px 3px 8px;

            @include respond-to(xxxl) {
              font-size: 16px;
              line-height: 19px;
            }
          }
        }

        .wrapper-titol-llistats {
          float: left;
          width: 100%;
          padding: 6px 0;
          font-size: 22px;
          line-height: 27px;
          margin-top: 12px;
          margin-bottom: 8px;

          a {
            font-size: 22px;
            line-height: 27px;
            font-weight: 100;
            border-bottom: 1px solid;
          }

          @include respond-to(xxxl) {
            a {
              font-size: 30px;
              line-height: 36px;
            }

          }
        }

        // Mas pequeno en REVISTA PAPER
        &.node-revista-en-paper {
          .content-wrapper {
            height: auto;
            min-height: 140px;
          }
        }

        &.node-agenda {
          @include respond-to(xxxl) {
            height: 530px;
          }

          .content-wrapper {
            height: auto;
            min-height: 204px;
          }
        }

        .field-name-field-image {
          float: left;
          width: 100%;
        }
      }

      .node-date {
        float: left;
        width: 100%;
        font-size: 14px;
        font-weight: 300;
        text-transform: uppercase;
        padding: 10px 0;
      }

      &:nth-child(3n) {
        .node {
          border-right: medium none;
        }
      }

      // RESPNSES
      @include respond-to(xl) {
        width: 50%;
        .node {
          border-right: 1px solid grey;
          padding: 0 20px;
          border-bottom: medium none;

          .content-wrapper {
            height: 280px;
            padding-top: 14px;
            position: relative;

            .share-wrapper {
              top: 10px;
              right: -10px;
            }
          }
        }
      }

      @include respond-to(xxl) {
        width: 33.3333%;
        margin-bottom: 10%;

        .field-name-field-image img {
          margin-bottom: 4px;
        }

        .node:hover {
          color: white;
          background-color: color(orange);

          a {
            color: white;
          }

          .wrapper-titol-llistats a {
            border-color: white;
          }

          .node .content-wrapper .share-wrapper .block-share-opener {
            background: url('/sites/all/themes/custom/nexe_mygs/assets/share_white.png') no-repeat 0 0 transparent;
          }

          .field-name-field-etiqueta-superior a:hover {
            background-color: white;
            color: black;
            border: medium none;
          }
        }
      }
    }

    .pager {
      margin-bottom: 20px;
    }
  }

}

// Bloque publi horitzontal
.view-display-id-block_publi_horitzontal {
  float: left;
  width: 100%;

  .publi-only-square,
  .publi-only-vertical,
  .publi-only-lateral {
    display: none;
    visibility: hidden;
    height: 0;
    overflow: hidden;
  }

  .publi-only-horizontal {
    img {
      width: 100%;
      height: 360px;
    }
  }
}

// Abridor de SAHRE en bloques ONLY
// TODO :: Correct for MOBILE !!!
.view-row .node {
  position: relative;

  .block-share-opener {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 30px;
    width: 30px;
    cursor: pointer;
    background: url(/sites/all/themes/custom/nexe_mygs/assets/share.png) no-repeat 0 0 transparent;

    &:hover {
      background-position: -30px 0;
    }
  }

  .share-buttons {
    display: block;
  }
}

// Clase para hacer HOVER sobre los nodos fuera de listado...
.view.block-hover .node {
  padding: 19px;

  &:hover {
    background-color: color(orange);

    .node-date {
      color: white;
    }

    a,
    a:visited {
      color: white;
      border-color: white;
    }
  }
}

// COMMON and Shared on VIEWS
.view-nexe-llistats,
#block-views-nexe-llistats-agenda-lateral,
.view-nexe-relacionats {

  .views-row {

    .destination {
      float: left;

      a {
        font-size: 16px;
        line-height: 19px;
      }
    }

    .field-name-field-etiqueta-superior {
      float: left;
      padding-left: 6px;

      a {
        box-sizing: border-box;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;
        border: 1px solid;
        padding: 5px 8px 3px 8px;
      }
    }

    .wrapper-titol-llistats {
      float: left;
      width: 100%;
      font-size: 22px;
      line-height: 27px;
      font-weight: 100;
      padding: 6px 0;
      margin-top: 12px;
      margin-bottom: 8px;

      a {
        font-weight: 300;
        text-decoration: none;
        border-bottom: 1px solid dimgrey;
      }

      @include respond-to(xxxl) {
        font-size: 30px;
        line-height: 36px;
      }
    }

    .field-authors-link {
      float: left;
      width: auto;
      display: inline-block;
      font-size: 15px;
      line-height: 18px;
      font-weight: 700;

      a {
        font-weight: 300;
        text-decoration: none;
        border-bottom: 1px solid dimgrey;
      }

      @include respond-to(xxxl) {
        margin-bottom: 8px;
      }
    }

    .field-name-field-text-lliure-autor {
      font-size: 15px;
      line-height: 18px;
      font-weight: 300;

      @include respond-to(xxl) {
        display: none;
      }
    }

    .field-name-field-subtitle {
      float: left;
      width: 100%;
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;
      margin-bottom: 8px;

      p {
        margin: 0;
      }
    }

    &:hover {
      .field-name-field-etiqueta-superior a {
        color: white;
        background-color: transparent;

        &:hover {
          color: black;
          background-color: white;
        }
      }
    }
  }
}


// EXCEPCIONES
// >>>>>>>>>>>>>>  BEGIN AGENDA LANDING !!!
// MOSTRAMOS AGENDA (que ya tiene contenido)
// Has this is the ONLY different, we style it here !!
//.page-views.page-agenda,
.page-views.page-hemeroteca {

  #title-wrapper.sticky .title-position .title-width {
    height: 105px;
    width: 676px;

    @include respond-to(xxxl) {
      width: 895px;
    }
  }

  .views-exposed-form {
    display: block !important;
    height: auto;
    overflow: visible;
    visibility: visible;
    opacity: 1;

    .views-exposed-widget {
      width: 100%;

      //.views-submit-button {
      //  border: medium none;
      //}

      .views-widget-sort-order {
        width: 100%;
        border-bottom: 1px solid;
      }
    }

    label {
      float: left;
      font-family: $ibmplex;
      font-size: 20px;
      line-height: 24px;
      font-weight: 100;
    }

    select#edit-sort-order {
      float: left;
      margin-left: 12px;
      font-family: $ibmplex;
      font-size: 20px;
      line-height: 24px;
      font-weight: 100;
      border: medium none;
      padding: 0 36px;
      outline: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background: url("/sites/all/themes/custom/nexe_mygs/assets/collapsible.png") no-repeat right center transparent;

      &:focus {
        background: url("/sites/all/themes/custom/nexe_mygs/assets/collapsible_orange.png") no-repeat right center transparent;
      }

      option {
        font-family: $ibmplex;
        font-size: 20px;
        line-height: 24px;
        font-weight: 100;
        padding: 0 36px;
      }
    }

    .form-item.form-type-select.form-item-sort-by {
      display: none;
    }
  }
}

// >>>>>>>>>>>>>>  END AGENDA LANDING !!!

// Diccionary (views page)
.view-glossary.view-id-glossary {
  float: left;
  width: 100%;

  &.view-display-id-page {
    margin-bottom: 20px;
    border-bottom: 1px solid;
    padding-bottom: 20px;

    @include respond-to(xl) {
      border-bottom: medium none;
    }
  }

  .view-display-id-attachment {
    position: relative;
    float: none;
    clear: both;
    width: 90%;
    margin: 0 auto;
    //background-color: red;

    .slick-prev {
      position: absolute;
      color: transparent;
      text-indent: 9999px;
      top: 5px;
      left: -18px;
      height: 20px;
      width: 14px;
      overflow: hidden;
      border: medium none;
      background: url("/sites/all/themes/custom/nexe_mygs/assets/arrow_left.png") no-repeat 0 0 transparent;
      outline: none;
    }

    .slick-next {
      position: absolute;
      color: transparent;
      text-indent: 9999px;
      top: 4px;
      right: -14px;
      height: 20px;
      width: 14px;
      overflow: hidden;
      border: medium none;
      background: url("/sites/all/themes/custom/nexe_mygs/assets/arrow_right.png") no-repeat 0 0 transparent;
      outline: none;
    }

    @include respond-to(xl) {
      float: left;
      clear: none;
      width: 100%;
      border-bottom: 1px solid #707070;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
  }

  .attachment.attachment-before {
    float: left;
    width: 100%;
  }

  .view-content.ui-accordion {
    float: left;
    width: 100%;
    margin-top: 18px;

    .views-summary.alpha {
      font-family: $ibmplex;
      font-size: 24px;
      line-height: 28px;
      font-weight: 300;
      padding: 0 9px;
      color: lightslategrey;

      a {
        font-weight: 400;
        text-decoration: none;

        &:hover {
          color: color(orange);
        }
      }

      &.active-trail {
        font-weight: 700;
        color: color(orange);

        a {
          color: color(orange);
        }
      }

      @include respond-to(xxl) {
        font-size: 18px;
        line-height: 22px;
        padding: 0 7px;
      }

      @include respond-to(xxxl) {
        font-size: 24px;
        line-height: 28px;
        padding: 0 9px;
      }
    }

  }

  // HEADER for each Accordion item
  .ui-accordion {

    .ui-accordion-header {
      border: medium none;
      background: none transparent;
      padding: .5em .5em .5em 0;

      // Different statuses
      &.ui-state-active {
        border: medium none;
      }

      // Main TITLE for LINK
      .field-content {
        font-family: $ibmplex;
        font-size: 30px;
        line-height: 36px;
        font-weight: 300;
        border-bottom: 1px solid;
      }

      .ui-accordion-header-icon {
        left: 250px;
        background: url(/sites/all/themes/custom/nexe_mygs/assets/collapsible.png) no-repeat 0 0 transparent;
        width: 20px;
        height: 24px;
        margin-top: -4px;
      }

      &:hover {
        .ui-accordion-header-icon {
          background: url(/sites/all/themes/custom/nexe_mygs/assets/collapsible_orange.png) no-repeat 0 0 transparent;
        }
      }
    }
  }


  // CONTENT for EACH Result !!
  .ui-accordion-content {
    padding: 1% 0;
    border: medium none;
    background: none transparent;
  }

  .views-field-nothing {
    float: left;
    width: 100%;

    .dictionary-char-box {
      float: left;
      width: 100%;
      height: 242px;
      text-align: center;
      background-color: #28DCE0;
      color: white;
      font-family: $ibmplex;
      font-size: 100px;
      line-height: 150px;
      font-weight: 100;
      padding-top: 44px;
    }

    .dictionary-share-box {
      // We dont need it anymore
      display: none;
      overflow: hidden;
      height: 0;
      visibility: hidden;
      opacity: 0;

      float: left;
      margin-top: 24px;
      width: 60%;
      height: 40px;
      //overflow: visible;
      position: relative;

      .share_open {
        width: 26px;
        height: 28px;
        cursor: pointer;
        background: url("/sites/all/themes/custom/nexe_mygs/assets/share.png") no-repeat 0 0 transparent;

        &:hover {
          background-position: -30px 0;
        }
      }

      .share-buttons {
        position: absolute;
        display: none;
        top: -10px;
        left: 64px;
        width: 132px;
      }
    }

    @include respond-to(xl) {
      width: 28%;

      .dictionary-share-box .share-buttons {
        top: -6px;
      }
    }

  }

  .views-field-body {
    float: left;
    width: 100%;
    font-family: $ibmplex;
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;

    p {
      margin-top: 0;
      font-family: $ibmplex;
      font-size: 16px;
      line-height: 22px;
      font-weight: 300;
    }

    @include respond-to(xl) {
      width: 72%;
      padding-left: 20px;
    }
  }

  .views-field-field-builder-blocks {
    float: left;
    width: 100%;

    @include respond-to(xl) {
      float: right;
      width: 72%;
      padding-left: 20px;
    }

    .field-name-field-title-bloc .field-item {
      display: inline-block;
      font-family: $ibmplex;
      font-size: 20px;
      line-height: 36px;
      font-weight: 300;
      border-bottom: 1px solid;
    }

    .field-name-field-text-bloc p {
      font-family: $ibmplex;
      font-size: 16px;
      line-height: 22px;
      font-weight: 300;
    }
  }
}

.views-row .field-name-field-autoria {
  float: left;
  width: 100%;

  .field-label {
    float: left;
    width: auto;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
  }

  > .field-items {
    float: left;
    width: auto;

    > .field-item {
      float: left;
      width: auto;
      //display: inline-block;
      position: relative;
      padding-right: 4px;
      margin-right: 6px;

      &:before {
        content: ',';
        position: absolute;
        right: 0;
      }

      &:last-child {
        padding: 0;
        margin: 0;

        &:before {
          content: '';
        }
      }

      .field-name-field-enllac {
        display: none;

        &:before {
          content: '';
          display: none;
        }
      }

      .field-collection-view,
      .entity-field-collection-item {
        padding: 0;
        margin: 0;

        .field-name-field-autor {
          float: left;
          width: auto;

          .field-item {
            padding: 0;
            margin: 0;
          }
        }

      }
    }
  }
}

.view-id-nexe_home .views-row .node-cartes-seniors .field-name-field-autoria > .field-items > .field-item {
  font-weight: 700;
  padding-right: 12px;

  &:last-child {
    padding-right: 0;
  }

  &::before {
    content: ' | ';
  }

  &:last-child:before {
    content: '';
  }
}

.view-footer {
  padding: 12px;

  .access-hemeroteca-articles {

    a {
      font-size: 22px;
    }

    p {
      margin: 12px 0;
    }
  }
}
