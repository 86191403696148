// Retrieve a font weight.
//
// @param string $weight
//   A weight from the $font-weight map.
//
// @return string
//   The selected font-weight.
@function weight($weight) {
  @if type-of($weight) == "string" {
    @if map-has-key($font-weight, $weight) {
      @return map-get($font-weight, $weight);
    }
    @else {
      @error "'#{$weight}' not found in $font-weight map";
    }
  }
  @else {
    @error "Weight specified for weight() is not a string";
  }
}

// Extend a font by adding a web-safe stack to it.
//
// Example usage:
//   $new-font-stack: extend-font-stack("Open sans", $sans-serif-stack);
//
// @param string $font
//   The name of the font. Use inverted commas if there are spaces in the font
//   name. i.e "Open sans"
// @param list $font-stack
//   The font stack variable to extend.
@function extend-font-stack($font, $font-stack) {
  @return join($font, $font-stack, $separator: comma);
}
